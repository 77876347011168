/* src/app/routes/configuration/logo/logo.component.scss */
.file-chooser > #file-input {
  display: none;
}
.center {
  text-align: center;
}
.profile-logo {
  height: 200px;
  width: 200px;
}
.hiddenCropper > div > image-cropper > div > .cropper {
  display: none !important;
}
.image {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.img-container:hover .image {
  opacity: 0.3;
}
.img-container:hover .middle {
  opacity: 1;
}
.action-button {
  background-color: #46435d;
  color: white;
  font-size: 12px;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
}
.upload-buttons {
  margin: 0 5px 0px 5px;
}
/*# sourceMappingURL=logo.component.css.map */
