/* src/app/routes/configuration/configuration/configuration.component.scss */
.content-center {
  justify-content: center;
}
.title-card {
  padding: 15px;
  font-weight: 400;
  font-size: 17px;
}
.save-button {
  text-align: right;
  line-height: 70px;
}
.center {
  text-align: center;
}
.profile-logo {
  height: 200px;
  width: 200px;
}
.image1 {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.card-height {
  height: 400px;
}
.capitals-profile {
  display: inline-block;
  font-size: 3.4em;
  width: 120px;
  height: 120px;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #666666;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
  margin: 25px;
  text-transform: uppercase;
}
.card-p {
  margin-bottom: 1.25rem;
  width: 100%;
  height: 170px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.page-tit {
  padding-top: 30px;
}
/*# sourceMappingURL=configuration.component.css.map */
