<div class='wrapper page container'>
  <div class="row page-tit">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'settings.title' | translate}}</h3>
      <button type="button" (click)="onSubmit()" class="btn blue-bg mg-12 float-right header-button">{{'settings.save'
        | translate}}</button>
    </div>
  </div>
  <div class="row content-center">
    <div class="col-md-12 col-xs-12">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="card card-p  border-0">
            <div class="row col-md-12" *ngIf="profile != 'RESELLER'">
              <div class="capitals-profile">
                {{ myData.name.charAt(0)+ myData.surname.charAt(0)}}
              </div>
              <span class="card-text">{{ myData.name }} {{ myData.surname }}</span>
            </div>
            <div class="row col-md-12" *ngIf="profile == 'RESELLER'">
              <div class="capitals-profile">
                {{ myData.name.charAt(0)}}
              </div>
              <span class="card-text">{{ myData.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-height">
        <div class="card-body ">
          <form [formGroup]="configurationForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
                <div class="has-float-label">
                  <ng-select class="custom" required  placeholder="{{ 'config.records-number' | translate }}" formControlName="number">
                    <ng-option *ngFor="let number of numbers" [value]="number.number">{{number.number}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="has-float-label">
                  <ng-select class="custom" required  placeholder="{{ 'config.time' | translate }}" formControlName="time">
                    <ng-option *ngFor="let time of times" [value]="time.number">{{time.number}}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
