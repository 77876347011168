import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent } from './configuration/configuration.component';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LogoComponent } from './logo/logo.component';
import { ImageCropperComponent } from 'ngx-image-cropper';


const routes: Routes = [
  { path: '', component: ConfigurationComponent },
 
];

@NgModule({
  imports: [
		TranslateModule,
    CommonModule,
    ImageCropperComponent,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forChild(routes),
  ],
  declarations: [ConfigurationComponent, LogoComponent],
  // exports
})

export class ConfigurationModule { }
