import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, Validators, ControlContainer } from '@angular/forms';
import { SettingsService } from '../../../core/settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { ColorsService } from '../../../shared/colors/colors.service';
import { Location } from '@angular/common';
import { SettingService } from '../../../services/setting.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationComponent implements OnInit {
  myData:any;
  configurationForm = this.fb.group({
    time: [localStorage.getItem("maxLoggedinDays"),Validators.required],
    number: [localStorage.getItem("tableRecordNumbers"),Validators.required],
  });
  logoPath;
  profile;
  numbers = [
    { number: 5 },
    { number: 10 },
    { number: 15 },
  ]

  times = [
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
  ]

  constructor( 
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private location: Location,
    public http: HttpClient,
    public settings: SettingsService,
    public colors: ColorsService,
    private settingService: SettingService) { 
      this.profile = localStorage.getItem("profile");
    }

  ngOnInit() {
    this.myData = {
      name: localStorage.getItem('name'),
      surname: localStorage.getItem('surname')
    }
  }

  onSubmit(){
    for (var i in this.configurationForm.controls) {
      this.configurationForm.controls[i].markAsTouched();
    }
    if (this.configurationForm.status == 'VALID') {
      this.save();
    }
  }

  save(){
    var maxLoggedinDays =  this.configurationForm.get("time").value;
    var tableRecordNumbers =  this.configurationForm.get("number").value;
    if(maxLoggedinDays != null) {
      localStorage.setItem("maxLoggedinDays",maxLoggedinDays);
    }
    if(tableRecordNumbers != null) {
      localStorage.setItem('tableRecordNumbers',tableRecordNumbers);
    }
    this.location.back();
  }

}
